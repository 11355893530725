// graphs.component.ts

import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import mixpanel from 'mixpanel-browser';

@Component({
  selector: 'app-graphs',
  templateUrl: './graphs.component.html',
  styleUrls: ['./graphs.component.scss']
})
export class GraphsComponent implements OnInit {
  cqaOrigin: string;
  graphTypes: string[] = ['default', 'Path Optimizer', 'Journey Map', 'Coverage Graph', 'Impact Path Viewer', 'Redundancy Eliminator'];
  graphUrls: SafeResourceUrl[] = [];
  tooltipDescriptions: { [key: string]: string } = {
    'default': 'A central hub for visualizing and navigating through test case graphs. Focuses on user-friendly exploration of test journeys.',
    'Path Optimizer': 'Allows users to visualize and interact with optimized paths based on the importance and weightage of actions. Ideal for prioritizing efficient test cases.',
    'Journey Map': 'A comprehensive view of the entire customer journey across multiple test cases. Helps users understand the most critical actions and their interrelations.',
    'Coverage Graph': 'A visualization tool that highlights the extent of test case coverage, including redundant nodes, emphasizing gaps that need to be addressed.',
    'Impact Path Viewer': 'Focuses on the impact of different test paths, displaying how each path contributes to overall coverage. Prioritizes the most important paths based on the number of children nodes.',
    'Redundancy Eliminator': 'A graph that showcases which test cases share repeated actions and helps users eliminate unnecessary nodes to streamline test creation.'
  };

  constructor(private sanitizer: DomSanitizer, private http: HttpClient) { }

  ngOnInit(): void {
    this.cqaOrigin = window.location.hostname.split('.')[0];
    mixpanel.identify(window.location.hostname.split('.')[0] + "-" + localStorage.getItem('useremail'));
    mixpanel.people.set({ "Plan": "Premium", "origin": window.location.hostname.split('.')[0], "email": localStorage.getItem('useremail') });
    mixpanel.track('Graphs');
    if (this.cqaOrigin === "localhost") {
      this.cqaOrigin = 'komtesales';
    }

    this.loadGraphs();
  }

  loadGraphs(): void {
    this.graphTypes.forEach((type, index) => {
      this.loadGraph(type).subscribe(url => {
        this.graphUrls[index] = url;
      });
    });
  }

  loadGraph(type: string): Observable<SafeResourceUrl> {
    const apiUrl = this.getApiUrl(type);
    return this.http.get(apiUrl, { responseType: 'text' }).pipe(
      map(() => this.sanitizer.bypassSecurityTrustResourceUrl(apiUrl)),
      catchError(() => {
        console.log(`Error loading graph from API for ${type}. Using fallback.`);
        return of(this.sanitizer.bypassSecurityTrustResourceUrl(this.getFallbackUrl(type)));
      })
    );
  }

  getApiUrl(type: string): string {
    switch (type) {
      case 'default':
        return `https://web-finder.contextqa.com/getgraph/file/${this.cqaOrigin}`;
      case 'Path Optimizer':
        return `https://web-finder.contextqa.com/getgraph/file/${this.cqaOrigin}_paths`;
      case 'Journey Map':
        return `https://web-finder.contextqa.com/getgraph/file/${this.cqaOrigin}_prioritize`;
      case 'Coverage Graph':
        return `https://web-finder.contextqa.com/getgraph/file/${this.cqaOrigin}_selected`;
      case 'Impact Path Viewer':
        return `https://web-finder.contextqa.com/getgraph/file/${this.cqaOrigin}_sorted_data`;
      case 'Redundancy Eliminator':
        return `https://web-finder.contextqa.com/getgraph/file/${this.cqaOrigin}_paths`;
      default:
        return `https://web-finder.contextqa.com/getgraph/file/${this.cqaOrigin}`;
    }
  }

  getFallbackUrl(type: string): string {
    switch (type) {
      case 'default':
        return `assets/graphs/${this.cqaOrigin}.html`;
      case 'Path Optimizer':
        return `assets/graphs/${this.cqaOrigin}_paths.html`;
      case 'Journey Map':
        return `assets/graphs/${this.cqaOrigin}_prioritize.html`;
      case 'Coverage Graph':
        return `assets/graphs/${this.cqaOrigin}_selected.html`;
      case 'Impact Path Viewer':
        return `assets/graphs/${this.cqaOrigin}_sorted_data.html`;
      case 'Redundancy Eliminator':
        return `assets/graphs/${this.cqaOrigin}_paths.html`;
      default:
        return `assets/graphs/${this.cqaOrigin}.html`;
    }
  }

  getTooltipDescription(type: string): string {
    return this.tooltipDescriptions[type] || 'No description available';
  }

  getGraphIcon(type: string): string {
    switch (type) {
      case 'default': return 'explore';
      case 'Path Optimizer': return 'route';
      case 'Journey Map': return 'map';
      case 'Coverage Graph': return 'pie_chart';
      case 'Impact Path Viewer': return 'assessment';
      case 'Redundancy Eliminator': return 'remove_circle';
      default: return 'graph';
    }
  }
}